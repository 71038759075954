import React, { useCallback, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Layout from "../../components/global/Layout/Layout";
import {
  templateWrapper,
  modalWrapper,
  modalFooter,
  customSearch,
} from "../../pageHelpers/Vendors/Vendors.module.scss";
import Preferred from "../../svg/preferred.svg";
import Approved from "../../svg/approved.svg";
import CustomPagination from "../../components/common/Pagination/CustomPagination";
import { graphql, PageProps } from "gatsby";
import { IVendorPageProps } from "./VendorPageProps";
import { getVendorType } from "../../services/utilities";
import { Button, Form, Modal } from "react-bootstrap";
import useMobileDetect from "../../hooks/useMobileDetect";
import { accolades, howToOrder, brandStandard } from "../../utils/Constants";
import Logo from "../../svg/access-point.svg";
import AuthHandler from "../../pageHelpers/Vendors/VendorsHelpers";

const showIcon = (type: string) => {
  const types = getVendorType(type);
  const typeName = types.length > 0 ? types[0].name : "";
  if (typeName === "Preferred Vendor") {
    return <Approved className="approved-logo" />;
  } else if (typeName === "Approved Vendor") {
    return <Preferred className="preferred-logo" />;
  } else {
    return <></>;
  }
};

const VendorsTemplate = (
  props: PageProps<IVendorPageProps, { category: string; id: string }>
) => {
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>({});
  const [brandStandards, setBrandStandards] = useState([]);
  const [vendorImage, setVendorImage] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const url = currentRow.logoUrl;
      const resourcesId = process.env.GATSBY_ACCESS_POINT_RESOURCES_ID;
      const imageResourcesId =
        process.env.GATSBY_ACCESS_POINT_RESOURCES_ID_IMAGES;
      const vendorLogosId = "cacdb4be-ea56-40b0-b18e-6c38e4be9561";
      if (url) {
        const imageName =
          url && url.substring(url.lastIndexOf("/") + 1, url.length);
        const graphUrl = `https://graph.microsoft.com/v1.0/sites/redlion.sharepoint.com,${resourcesId},${imageResourcesId}/lists/${vendorLogosId}/drive/root:/${imageName}`;
        const headers = {
          "content-type": "application/json",
          authorization: `Bearer ${
            typeof window !== "undefined" ? localStorage.getItem("token") : ""
          }`,
        };
        const options = {
          method: "GET",
          headers: headers,
        };
        const response = await fetch(graphUrl, options);
        const message = await response.json();

        if (message["@microsoft.graph.downloadUrl"]) {
          console.log(
            "image url-------",
            message["@microsoft.graph.downloadUrl"]
          );
          setVendorImage(message["@microsoft.graph.downloadUrl"]);
        } else {
          throw new Error("Download URL not found in response.");
        }
      }
    };
    fetchImage();
  }, [currentRow]);

  const handleOpenModal = (row: any) => {
    setOpen(true);
    setCurrentRow(row);
    setBrandStandards(row?.brandStandard.split(","));
  };
  const closeModal = () => {
    setOpen(false);
    setCurrentRow({});
  };
  const columns = [
    {
      text: "",
      dataField: "type",
      formatter: showIcon,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "title",
      text: "SUPPLIER",
      formatter: (val: string, row: any) =>
        val ? (
          <span className="name-link" onClick={() => handleOpenModal(row)}>
            {val}
          </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "fullName",
      text: "CONTACT",
    },
    {
      dataField: "telephone",
      text: "PHONE",
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "contact.brandEmail",
      text: "E-MAIL",
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "subCategory2",
      text: "PRODUCT",
    },
    {
      dataField: "websiteUrl",
      text: "URL",
      formatter: (val: string) =>
        val ? (
          <a href={val} target="_blank" className="url-link">
            {val}
          </a>
        ) : (
          ""
        ),
    },
  ];
  const mobileColumns = [
    {
      text: "",
      dataField: "type",
      formatter: showIcon,
      headerStyle: () => {
        return { width: "70px" };
      },
    },
    {
      dataField: "title",
      text: "SUPPLIER",
      formatter: (val: string, row: any) =>
        val ? (
          <span className="name-link" onClick={() => handleOpenModal(row)}>
            {val}
          </span>
        ) : (
          ""
        ),
    },

    {
      dataField: "subCategory2",
      text: "PRODUCT",
    },
  ];
  const isMobileOnly = useMobileDetect();
  const vendorsByCategory = [...props.data.Gapi.vendorsByCategory];
  const vendorsData = vendorsByCategory.map(x => {
    const v = {
      ...x,
      fullName: `${x.contact.firstName} ${x.contact.lastName}`,
    };
    return v;
  });
  const [vendors, setVendors] = useState<
    Array<{
      contact: { brandEmail: string };
      websiteUrl: string;
      telephone: string;
      fullName: string;
      title: string;
      subCategory2: string;
    }>
  >([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setVendors(vendorsData);
  }, []);
  const [pagination, setPagination] = useState({
    totalCount: vendors.length,
    numPages: Math.ceil(vendors.length / 10),
    currentPage: 1,
    offset: 0,
    endOffSet: 10,
    limit: 10,
  });
  const handlePagination = (data: { selected: number }) => {
    setPagination({
      ...pagination,
      currentPage: data.selected + 1,
      offset: data.selected * pagination.limit,
      endOffSet: (data.selected + 1) * pagination.limit,
    });
  };
  const paginationMessage = () =>
    `Showing ${pagination.offset === 0 ? 1 : pagination.offset} to ${
      vendors.length < pagination.endOffSet
        ? vendors.length
        : pagination.endOffSet
    } of ${vendors.length} suppliers.`;

  const filterByValue = (
    arrayOfObject: Array<{
      contact: { brandEmail: string };
      websiteUrl: string;
      telephone: string;
      fullName: string;
      title: string;
      subCategory2: string;
    }>,
    term: string
  ) => {
    const filterVendor = arrayOfObject.filter(function (v) {
      if (
        v.contact?.brandEmail?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.websiteUrl?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.telephone?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.fullName?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.title?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        v.subCategory2?.toLowerCase().indexOf(term.toLowerCase()) > -1
      ) {
        return true;
      } else false;
    });
    setPagination({
      ...pagination,
      totalCount: filterVendor.length,
      numPages: Math.ceil(filterVendor.length / 10),
      currentPage: 1,
      offset: 0,
      endOffSet: 10,
      limit: 10,
    });
    setVendors(filterVendor);
  };
  useEffect(() => {
    filterByValue(vendorsData, searchValue);
  }, [searchValue]);
  const onClearSearch = () => {
    setSearchValue("");
  };

  const CustomSearch = useCallback(
    ({ value }: { value: string }) => (
      <div className={customSearch}>
        <div className="clearable-input">
          <input
            className="form-control"
            placeholder="Search Suppliers"
            type="text"
            name="searchValue"
            value={value}
            onChange={e => setSearchValue(e.target.value)}
          />
          <span data-clear-input onClick={onClearSearch}>
            &times;
          </span>
        </div>
      </div>
    ),
    []
  );

  return (
    <Layout>
      <ToolkitProvider
        keyField="title"
        data={vendors.slice(pagination.offset, pagination.endOffSet)}
        columns={isMobileOnly ? mobileColumns : columns}
        search
      >
        {data => (
          <div className={templateWrapper}>
            <div className="row mb-3">
              <div className="col-md-6">
                <h3 className="category">
                  {`${props.pageContext.category}`} Supplier Listing
                </h3>
              </div>
              <AuthHandler />
              <div className="col-md-6">
                <div className="searchBar">
                  <CustomSearch value={searchValue} />
                </div>
              </div>
            </div>

            <BootstrapTable
              {...data.baseProps}
              hover
              bordered={false}
              rowClasses="custom-table-class"
            />
            {vendors.length ? (
              <>
                <div className="row" style={{ margin: "auto" }}>
                  <div
                    className="row footer-wrapper"
                    style={{ margin: "auto" }}
                  >
                    <div className="col-md-6">
                      <span>{paginationMessage()}</span>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex pagination-wrapper ">
                        <CustomPagination
                          numPages={pagination.numPages}
                          itemsPerPage={pagination.limit}
                          currentPage={pagination.currentPage}
                          handlePageClick={handlePagination}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{ display: "inline", margin: "auto" }}
                    >
                      <span>
                        <Preferred className="preferred-logo" />
                        &nbsp;Preferred
                      </span>
                      <span>
                        <Approved className="approved-logo" />
                        &nbsp;Approved
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center mt-3">No data found</div>
            )}
          </div>
        )}
      </ToolkitProvider>
      <Modal centered show={open} size="xl" className="" onHide={setOpen}>
        <Modal.Body>
          <div className={modalWrapper}>
            <div className="modalHeader">
              <div>
                <span className="title">{currentRow?.title} Profile</span>&nbsp;
              </div>
              <div>
                <img src={vendorImage} alt={currentRow.category} />
              </div>
              {/* <Logo className="logo" /> */}
            </div>

            <hr />

            <span>{showIcon(currentRow?.type)}</span>
            <div className="row mt-2">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">Contact</Form.Label>
                  <p className="field-content">
                    {currentRow?.contact?.firstName +
                      " " +
                      currentRow?.contact?.lastName}
                  </p>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">E-mail</Form.Label>
                  <p className="field-content">
                    <a
                      href={`mailto:${currentRow?.contact?.brandEmail}`}
                      className="link"
                    >
                      {currentRow?.contact?.brandEmail}
                    </a>
                  </p>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">Phone Number</Form.Label>
                  <p className="field-content">{currentRow.telephone}</p>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">Website Url</Form.Label>
                  <p className="field-content">
                    <a
                      href={currentRow.websiteUrl}
                      target="_blank"
                      className="link"
                    >
                      {currentRow.websiteUrl}
                    </a>
                  </p>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">How To Order</Form.Label>
                  <p className="field-content">
                    {currentRow &&
                      currentRow?.howToOrder &&
                      howToOrder[currentRow?.howToOrder]}
                  </p>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="field-label">
                    Sonesta Accolades
                  </Form.Label>
                  <p className="field-content">
                    {currentRow &&
                      currentRow?.accolades &&
                      accolades[currentRow?.accolades]}
                  </p>
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group>
                  <Form.Label className="field-label">
                    Brand Standard
                  </Form.Label>
                  {brandStandards.length === 13 ? (
                    <p className="field-content">All Brands</p>
                  ) : (
                    <div>
                      <ul
                        className={
                          brandStandards.length > 3 ? "brand-list" : ""
                        }
                      >
                        {brandStandards.map((brandStandardId: string) => (
                          <li key={brandStandardId} className="field-content">
                            {brandStandard[brandStandardId]}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group>
                  <Form.Label className="field-label">Description</Form.Label>
                  <p className="field-content">{currentRow.description}</p>
                </Form.Group>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={modalFooter}>
          <Button variant="secondary" size="sm" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};
export const query = graphql`
  query ($category: String!) {
    Gapi {
      vendorsByCategory(category: $category) {
        category
        contact {
          brandEmail
          firstName
          lastName
          personalEmail
        }
        description
        email
        logoUrl
        brandStandard
        howToOrder
        accolades
        subCategory1
        subCategory2
        telephone
        title
        websiteUrl
        type
      }
    }
  }
`;
export default VendorsTemplate;
